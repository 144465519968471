<template>
  <v-row class="rowDialog">
    <v-dialog v-model="dialog" persistent :fullscreen="$vuetify.breakpoint.smAndDown"
      max-width="430">
      <v-card>
        <v-card-title class="pb-0">
          <v-col cols="10" v-if="keyCreate">
            Cadastro de chave
          </v-col>
          <v-col cols="10" v-if="keyDelete">
            Remover chave
          </v-col>
          <v-spacer></v-spacer>
          <v-col class="close-icon" cols="1" @click="cancelModal">
            <v-icon>
              mdi-window-close
            </v-icon>
          </v-col>
        </v-card-title>
        <v-col cols="12" class="pb-0 pt-0" align="center">
          <v-divider></v-divider>
        </v-col>
        <v-col v-if="getLoading" cols="12" align="center">
        <v-progress-circular
          :size="100"
          color="#D70472"
          indeterminate
          ></v-progress-circular>
          <h3 class="textInfoDisable mt-6">Carregando...</h3>
        </v-col>
        <v-card-text v-show="!getLoading" v-if="sendToken && !successCreate && !successDelete">
           <v-row justify="center">
            <v-col md="7" cols="12" class="modal-text pt-12" align="center">Para completar a operação, informe o token gerado:</v-col>
            <v-col md="9" cols="12" class="token-text pt-5 pb-0" align="center">
              Insira o token de 6 dígitos
            </v-col>
            <v-col md="7" cols="12" class="mt-0 pb-0 pt-0 token">
              <v-text-field
                class="token-field"
                maxlength="6"
                placeholder="------"
                rounded
                type="password"
                v-mask="'######'"
                single-line
                dense
                outlined
                v-model="token"
              />
            </v-col>
            <v-col cols="12" md="7" class="pb-5 pt-md-8 pt-12" align="center">
              <v-btn
                v-if="keyCreate"
                rounded
                color="#D70472"
                block
                :disabled="!hasPassword"
                :dark="hasPassword"
                :ligth="!hasPassword"
                @click="registerKey"
                class="button-modal pa-6 pa-md-1"
              >Confirmar cadastro</v-btn>
              <v-btn
                v-if="keyDelete"
                rounded
                color="#D70472"
                block
                :disabled="!hasPassword"
                :dark="hasPassword"
                :ligth="!hasPassword"
                @click="removeKey"
                class="button-modal pa-6 pa-md-1"
              >Remover chave</v-btn>
              <v-btn
                @click="cancelModal"
                rounded
                color="#8C8C8C"
                block
                outlined
                class="button-modal pa-6 pa-md-1 mt-5 mt-md-3"
              >Cancelar</v-btn>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-text v-if="successCreate || successDelete">
           <v-row justify="center">
            <v-col align="center"  cols="12" class="pt-8 pb-0">
                <img src="@/assets/key-icon.png" alt="" v-if="successCreate">
                <img src="@/assets/erro.png" alt="" v-if="successDelete">
            </v-col>
            <v-col md="7" cols="8" class="modal-text pt-0 pl-8 pr-8" align="center" v-if="successCreate">Cadastro de chave efetuado com sucesso!</v-col>
            <v-col md="7" cols="8" class="modal-text pt-0 pl-8 pr-8 pt-4" align="center" v-if="successDelete">Chave removida com sucesso</v-col>
            <v-col md="7" cols="8" class="token-text pt-2 pb-0" align="center">
              Para gerenciar as suas chaves cadastradas, acesse a opção ”PIX” no menu lateral
            </v-col>
            <v-col cols="12" md="7" class="pb-5 pt-md-5 pt-12" align="center">
              <v-btn
                rounded
                color="#D70472"
                block
                dark
                @click="registerKeys"
                class="button-modal pa-6 pa-md-1"
              >Cadastrar mais chaves</v-btn>
              <v-btn
                @click="closeModal"
                rounded
                color="#8C8C8C"
                block
                outlined
                class="button-modal pa-6 pa-md-1 mt-5 mt-md-3"
              >Fechar</v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import { mask } from "vue-the-mask";
import service from "../../../../service/index";

export default {
  directives: {
    mask,
  },
  props: ["dialog"],
  data() {
    return {
      token: "",
      sendToken: true,
      successCreate: false,
      successDelete: false,
      keyCreate: false,
      keyDelete: false,
    };
  },
    watch: {
    dialog() {
      this.token = '';
      this.successCreate =  false;
      this.successDelete =  false;
      if(this.getRequest) {
        if(this.getRequest[0] === "create") {
          this.keyCreate = true
          this.keyDelete = false
        } else if (this.getRequest[0] === "delete") {
          this.keyDelete = true
          this.keyCreate = false
        }
      }
    }
  },
    computed: {
    ...mapGetters({
      getDataKey: 'Pix/getDataKey',
      getTokenSended: 'Pix/getTokenSended',
      erro: "Pix/getError",
      getKeyExists: 'Pix/getKeyExists',
      getRequest: 'Pix/getRequest',
      getDataKeys:'Pix/getAllDataKeys',
      getLoading:'Loading/getLoading'
    }),
    hasPassword() {
      return this.token.length === 6;
    },
  },
  methods: {
    ...mapActions({
      createKey: 'Pix/createPixKey',
      createOtherKeys: 'Pix/createOtherKeys',
      deletePixKey:'Pix/deletePixKey',
      getAllDataKeys: 'Pix/getAllPixKeys',
      updateStatusModal: 'Pix/updateStatusModal',
    }),
    ...mapMutations({
      setDataKey: 'Pix/SET_DATA_KEY',
      setKeyExists: 'Pix/SET_KEY_EXISTS',
      setRequest: 'Pix/SET_REQUEST',
    }),
    closeModal() {
      this.setRequest(null);
      this.setDataKey(null);
      this.$emit("closeModal");
    },
    cancelModal() {
      this.setRequest(null);
      this.setDataKey(null);
      this.$emit("cancelModal");
    },
    async registerKey() {
      if(this.getDataKey[0] === "mobile_phone" || this.getDataKey[0] === "email") {
        await this.createOtherKeys({
          name: this.getDataKey[1],
          type: this.getDataKey[0],
          code: this.token,
        })
      } 
      if (this.getDataKey[0] === "random" || this.getDataKey[0] === "cnpj") {
        await this.createKey({
          token: this.token,
          name: this.getDataKey[1],
          type: this.getDataKey[0],
        })
      }
      if (this.erro) {
        return
        } else {
          this.successCreate = true;
          this.verifyKey();
          return
        }
    },
    async removeKey() {
      await this.deletePixKey({
        token: this.token,
        name: this.getRequest[1]
      })
      if (this.erro) {
        return
        } else {
          this.successDelete = true
          await this.getAllDataKeys({
            "page":1,
            "perPage":20
          })
          if(this.getDataKeys.items[0].alias !== null && this.getDataKeys.items[0].alias.length !== 0) {
            this.setKeyExists(true)
          } else {
            this.setKeyExists(false)
          }
        }
    },
    async verifyKey() {
      await this.updateStatusModal({
        showAliasModal: false
      })
     await this.getAllDataKeys({
        "page":1,
        "perPage":20
      })
      if(this.getDataKeys.items[0].alias !== null && this.getDataKeys.items[0].alias.length !== 0) {
        this.setKeyExists(true)
        this.$emit("pushToMyKeys");
      } else {
        this.$emit("pushToMyKeys");
      }
    },
    registerKeys() {
      this.setRequest(null);
      this.setDataKey(null);
      this.$emit("closeModal");
      this.$emit("pushToRegistration");
    }
  },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/styles/digitalAccount/pix.scss';
</style>
