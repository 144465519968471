<template>
  <v-container>
    <ModalRegistration
      :dialog="dialog"
      @closeModal="closeModal"
      @cancelModal="cancelModal"
      @pushToMyKeys="pushToMyKeys"
      @pushToRegistration="pushToRegistration"
    />
    <v-row>
      <v-col md="12" cols="12" class="presentation-column pb-md-0">
        <v-row v-if="firstKeys || (registrationKeys && $vuetify.breakpoint.smAndDown)">
          <v-col align="left" cols="12" md="1" class="pt-3 pb-md-3 pb-0">
            <img src="@/assets/key-shadow.svg" alt="" />
          </v-col>
          <v-col md="9" cols="12" class="pl-md-3 pt-0 pt-md-3">
            <v-col md="12" cols="7" class="title pb-1 pt-md-3 pt-0 pl-md-3 pl-0">
              Cadastro de chave
            </v-col>
            <v-col md="12" cols="10" class="text text-register pb-10 pl-2">
              Selecione o tipo de chave que deseja cadastrar no Pix.<br />
              Você pode cadastrar até 20 chaves.
            </v-col>
            <v-row justify="space-between" class="pb-5">
              <v-col
                md="5"
                cols="11"
                :class="
                  !arrowUpDocument
                    ? 'select-cards-open mb-5 mb-md-0'
                    : 'select-cards-hide mb-5 mb-md-0'
                "
                v-if="enableDocument"
              >
                <v-row>
                  <v-radio-group v-model="radioKey" cols="1" class="pa-0 pl-6 mt-3">
                    <v-radio color="#D70472" value="document"></v-radio>
                  </v-radio-group>
                  <v-col cols="8" class="select-titles pb-0 pl-0">CNPJ</v-col>
                  <v-icon @click="arrowUpDocument = !arrowUpDocument" class="pb-2">{{
                    arrowUpDocument ? "mdi-chevron-down" : "mdi-chevron-up"
                  }}</v-icon>
                </v-row>
                <v-col cols="12" class="input-register pt-0 pl-10" v-if="!arrowUpDocument">
                  <v-text-field v-if="account" v-model="setDocument" readonly></v-text-field>
                </v-col>
              </v-col>
              <v-col
                md="5"
                cols="11"
                :class="
                  arrowUpMail && radioKey === 'mail'
                    ? 'select-cards-open mr-10 mb-5 mb-md-0'
                    : 'select-cards-hide mr-10'
                "
              >
                <v-row>
                  <v-radio-group v-model="radioKey" cols="1" class="pa-0 pl-6 mt-3">
                    <v-radio color="#D70472" value="mail"></v-radio>
                  </v-radio-group>
                  <v-col cols="8" class="select-titles pb-0 pl-0">E-mail</v-col>
                  <v-icon class="pb-2" @click="arrowUpMail = !arrowUpMail">{{
                    arrowUpMail && radioKey === "mail" ? "mdi-chevron-up" : "mdi-chevron-down"
                  }}</v-icon>
                </v-row>
                <v-form
                  ref="mail"
                  lazy-validation
                  cols="12"
                  class="input-register pt-0 pl-10 pr-3"
                  v-if="arrowUpMail && radioKey === 'mail'"
                >
                  <v-text-field
                    placeholder="maria@infracommerce.com.br"
                    v-model="mailKey"
                    :rules="validationEmail"
                    validate-on-blur
                  ></v-text-field>
                </v-form>
              </v-col>
            </v-row>
            <v-row justify="space-between" class="pb-5">
              <v-col
                md="5"
                cols="11"
                :class="
                  arrowUpPhone && radioKey === 'phone'
                    ? 'select-cards-open mb-5 mb-md-0'
                    : 'select-cards-hide mb-5 mb-md-0'
                "
              >
                <v-row>
                  <v-radio-group v-model="radioKey" cols="1" class="pa-0 pl-6 mt-3">
                    <v-radio color="#D70472" value="phone"></v-radio>
                  </v-radio-group>
                  <v-col cols="8" class="select-titles pb-0 pl-0">Celular</v-col>
                  <v-icon @click="arrowUpPhone = !arrowUpPhone" class="pb-2">{{
                    arrowUpPhone && radioKey === "phone" ? "mdi-chevron-up" : "mdi-chevron-down"
                  }}</v-icon>
                </v-row>
                <v-form
                  ref="phone"
                  lazy-validation
                  cols="12"
                  class="input-register pt-0 pl-10 pr-3"
                  v-if="arrowUpPhone && radioKey === 'phone'"
                >
                  <v-text-field
                    placeholder="(11) 0 0000-0000"
                    v-model="phoneKey"
                    v-mask="'+55(##)#####-####'"
                    :rules="validationMobileNumber"
                    validate-on-blur
                  ></v-text-field>
                </v-form>
              </v-col>
              <v-col md="5" cols="11" class="select-cards-hide aleatory mr-10">
                <v-row>
                  <v-radio-group v-model="radioKey" cols="1" class="pa-0 pl-6 mt-3">
                    <v-radio color="#D70472" value="aleatory"></v-radio>
                  </v-radio-group>
                  <v-col cols="9" class="select-titles pb-0 pl-0">Aleatória*</v-col>
                  <v-col cols="12" class="aleatory-text pt-1"
                    >A chave aleatória é gerada automaticamente clicando em <br />
                    ”Registrar chave” e será composta por letras e números</v-col
                  >
                </v-row>
              </v-col>
            </v-row>
            <v-col md="4" class="pt-12 pt-md-3 mt-8 mt-md-0">
              <v-btn
                class="btn-register"
                dark
                :x-large="$vuetify.breakpoint.smAndDown"
                rounded
                color="#D70472"
                @click="sendToken"
                block
                >Registrar chaves</v-btn
              >
            </v-col>
            <v-col md="4" class="pt-1 pt-md-3" v-if="$vuetify.breakpoint.smAndDown">
              <v-btn
                class="btn-register"
                outlined
                x-large
                rounded
                color="#8C8C8C"
                @click="cancel"
                block
                >Cancelar</v-btn
              >
            </v-col>
          </v-col>
        </v-row>
        <v-row v-if="myKeys">
          <v-col align="left" cols="12" md="1" class="pt-3">
            <img src="@/assets/key-shadow.svg" alt="" />
          </v-col>
          <v-col md="9" cols="12" class="pl-md-3 pt-0 pt-md-3 pl-0">
            <v-col md="12" cols="6" class="title pb-1 pt-0 pt-md-3">
              Minhas chaves
            </v-col>
            <v-col md="12" cols="12" class="text text-register  pb-10 pl-4 pl-md-3">
              Gerencie as suas chaves cadastradas no Pix.<br />Você pode cadastrar até 20 chaves.
            </v-col>
            <v-col class="pt-0 pl-0">
              <v-col md="10" class="pl-0 pr-0 pt-0">
                <v-col
                  md="12"
                  class="registration-cards-title aleatory register mr-10 ml-3"
                  @click="pushToRegistration"
                >
                  <v-row justify="space-between">
                    <v-col md="4" cols="8" class="register-title pb-0 pl-8">Registrar chave</v-col>
                    <v-col md="1" cols="2" class="register-title pt-0 mr-3">
                      <h1>+</h1>
                    </v-col>
                  </v-row>
                </v-col>
              </v-col>
              <v-col
                md="10"
                cols="12"
                class="select-cards-hide aleatory mr-10 ml-3 mb-2"
                v-for="(item, i) in getDataKeys.items[0].alias"
                :key="i"
                v-if="item.status === true"
              >
                <v-row justify="space-between">
                  <v-col cols="7" class="pt-0 pb-0">
                    <v-row>
                      <v-col
                        md="4"
                        cols="12"
                        class="select-titles pb-0 pl-md-8 pl-6 pr-0 pr-md-2 pt-md-3 pt-1"
                        >{{
                          item.type === "cnpj"
                            ? "CNPJ"
                            : item.type === "random"
                            ? "Aleatória"
                            : item.type === "mobile_phone"
                            ? "Celular"
                            : item.type === "email"
                            ? "E-mail"
                            : ""
                        }}</v-col
                      >
                      <v-col
                        md="7"
                        cols="5"
                        v-if="item.type === 'cnpj'"
                        class="data-key mt-1 pl-md-0 pl-0 pt-md-3 pt-0 ml-md-2 ml-6"
                        >{{ service.documentFormat(item.name) }}</v-col
                      >
                      <v-col
                        md="7"
                        cols="5"
                        v-else
                        class="data-key mt-1 pl-md-0 pl-0 pt-md-3 pt-0 ml-md-2 ml-6"
                        >{{ item.name }}</v-col
                      >
                    </v-row>
                  </v-col>
                  <v-col
                    cols="3"
                    align="right"
                    class="pr-8"
                    v-if="$vuetify.breakpoint.smAndDown"
                    @click="requestDelete(item.name)"
                  >
                    <v-icon>
                      mdi-delete
                    </v-icon>
                  </v-col>
                  <v-col cols="5" align="right" class="pr-6 pt-2" v-else>
                    <v-btn
                      rounded
                      color="#D70472"
                      outlined
                      dark
                      small
                      class="button"
                      @click="requestDelete(item.name)"
                      >Remover chave</v-btn
                    >
                  </v-col>
                </v-row>
              </v-col>
            </v-col>
          </v-col>
        </v-row>
        <v-row v-if="registrationKeys && $vuetify.breakpoint.mdAndUp">
          <v-col align="left" cols="1" class="pt-3">
            <img src="@/assets/key-shadow.svg" alt="" />
          </v-col>
          <v-col cols="9" class="pl-md-3">
            <v-col md="12" class="title pb-1">
              Cadastro de chave
            </v-col>
            <v-col md="12" class="text pb-10">
              Selecione o tipo de chave que deseja cadastrar no Pix.<br />Você pode cadastrar até 20
              chaves.
            </v-col>
            <v-col :md="enableDocument ? '7' : '6'" class="pt-0 pl-4">
              <v-row justify="space-between">
                <v-col md="3" class="pt-0" v-if="enableDocument">
                  <v-btn
                    :color="activeDocument ? '#C4C4C4' : '#D70472'"
                    :dark="!activeDocument"
                    :light="activeDocument"
                    :outlined="activeDocument"
                    @click="setActiveDocument()"
                    :class="activeDocument ? 'button-notselected' : 'button-selected'"
                    >CNPJ</v-btn
                  >
                </v-col>
                <v-col :md="enableDocument ? '3' : '4'" class="pt-0">
                  <v-btn
                    :color="activeMail ? '#C4C4C4' : '#D70472'"
                    :dark="!activeMail"
                    :light="activeMail"
                    :outlined="activeMail"
                    @click="setActiveMail()"
                    :class="activeMail ? 'button-notselected' : 'button-selected'"
                    >E-mail</v-btn
                  >
                </v-col>
                <v-col :md="enableDocument ? '3' : '4'" class="pt-0">
                  <v-btn
                    :color="activePhone ? '#C4C4C4' : '#D70472'"
                    :dark="!activePhone"
                    :light="activePhone"
                    :outlined="activePhone"
                    @click="setActivePhone()"
                    :class="activePhone ? 'button-notselected' : 'button-selected'"
                    >Celular</v-btn
                  >
                </v-col>
                <v-col :md="enableDocument ? '3' : '4'" class="pt-0">
                  <v-btn
                    :color="activeAleatory ? '#C4C4C4' : '#D70472'"
                    :dark="!activeAleatory"
                    :light="activeAleatory"
                    :outlined="activeAleatory"
                    @click="setActiveAleatory()"
                    :class="activeAleatory ? 'button-notselected' : 'button-selected'"
                    >Aleatória</v-btn
                  >
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="6" class="pt-0 pl-4 pr-8" v-if="!activeDocument">
              <v-text-field v-if="account" v-model="setDocument" readonly></v-text-field>
              <v-row justify="space-between">
                <v-col md="4" class="pl-3">
                  <v-btn
                    class="btn-register"
                    dark
                    rounded
                    outlined
                    color="#D70472"
                    @click="pushToMyKeys"
                    block
                    >Voltar</v-btn
                  >
                </v-col>
                <v-col md="6" class="pl-0">
                  <v-btn
                    class="btn-register"
                    dark
                    rounded
                    color="#D70472"
                    @click="sendToken('document')"
                    block
                    >Registrar chave</v-btn
                  >
                </v-col>
              </v-row>
            </v-col>
            <v-form ref="mail" lazy-validation>
              <v-col cols="6" class="pt-0 pl-4 pr-8" v-if="!activeMail">
                <v-text-field
                  label="Preencha o dado abaixo:"
                  placeholder="Insira seu e-mail"
                  v-model="mailKey"
                  :rules="validationEmail"
                  validate-on-blur
                ></v-text-field>
                <v-row justify="space-between">
                  <v-col md="4" class="pl-3">
                    <v-btn
                      class="btn-register"
                      dark
                      rounded
                      outlined
                      color="#D70472"
                      @click="pushToMyKeys"
                      block
                      >Voltar</v-btn
                    >
                  </v-col>
                  <v-col md="6" class="pl-0">
                    <v-btn
                      class="btn-register"
                      dark
                      rounded
                      color="#D70472"
                      @click="sendToken"
                      block
                      >Registrar chave</v-btn
                    >
                  </v-col>
                </v-row>
              </v-col>
            </v-form>
            <v-form ref="phone" lazy-validation>
              <v-col cols="6" class="pt-0 pl-4 pr-8" v-if="!activePhone">
                <v-text-field
                  label="Preencha o dado abaixo:"
                  placeholder="Insira seu celular"
                  v-model="phoneKey"
                  v-mask="'+55 (##)#####-####'"
                  :rules="validationMobileNumber"
                  validate-on-blur
                ></v-text-field>
                <v-row justify="space-between">
                  <v-col md="4" class="pl-3">
                    <v-btn
                      class="btn-register"
                      dark
                      rounded
                      outlined
                      color="#D70472"
                      @click="pushToMyKeys"
                      block
                      >Voltar</v-btn
                    >
                  </v-col>
                  <v-col md="6" class="pl-0">
                    <v-btn
                      class="btn-register"
                      dark
                      rounded
                      color="#D70472"
                      @click="sendToken()"
                      block
                      >Registrar chave</v-btn
                    >
                  </v-col>
                </v-row>
              </v-col>
            </v-form>
            <v-col cols="8" class="pt-0 pl-4 pr-8" v-if="!activeAleatory">
              <v-col md="12" class="pl-0">
                A chave aleatória é gerada automaticamente<br />
                clicando em <b>”Registrar chave”</b> e será <br />composta por letras e números.
              </v-col>
              <v-row justify="space-between">
                <v-col md="4" class="pl-3">
                  <v-btn
                    class="btn-register"
                    dark
                    rounded
                    outlined
                    color="#D70472"
                    @click="pushToMyKeys"
                    block
                    >Voltar</v-btn
                  >
                </v-col>
                <v-col md="6" class="pl-0">
                  <v-btn
                    class="btn-register"
                    dark
                    rounded
                    color="#D70472"
                    @click="sendToken('aleatory')"
                    block
                    >Registrar chave</v-btn
                  >
                </v-col>
              </v-row>
            </v-col>
            <v-col
              md="2"
              class="pl-3"
              v-if="activeAleatory && activePhone && activeMail && activeDocument"
            >
              <v-btn
                class="btn-register"
                dark
                rounded
                outlined
                color="#D70472"
                @click="pushToMyKeys"
                block
                >Voltar</v-btn
              >
            </v-col>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import { mask } from "vue-the-mask";
import ModalRegistration from "./ModalRegistration.vue";
import service from "../../../../service/index";

export default {
  directives: {
    mask
  },
  components: {
    ModalRegistration
  },
  data() {
    return {
      radioKey: "",
      documentKey: "",
      phoneKey: "",
      mailKey: "",
      arrowUpDocument: true,
      arrowUpMail: true,
      arrowUpPhone: true,
      dialog: false,
      activeDocument: true,
      activeAleatory: true,
      activePhone: true,
      activeMail: true,
      firstKeys: false,
      myKeys: false,
      registrationKeys: false,
      service,
      firstAccess: false,
      enableDocument: true,
      mobilePhone: "",
      abledKey: false
    };
  },
  computed: {
    ...mapGetters({
      role: "Auth/getRole",
      account: "DigitalAccount/getAccount",
      getKeyExists: "Pix/getKeyExists",
      getDataKeys: "Pix/getAllDataKeys",
      erro: "Pix/getError"
    }),
    validationMobileNumber() {
      return [
        valueInput => !!valueInput || "Número de celular obrigatório",
        valueInput => (valueInput && valueInput.length >= 17) || "Deve conter 13 números"
      ];
    },
    validationEmail() {
      return [
        valueInput => !!valueInput || "Insira o e-mail",
        valueInput => !valueInput || /.+@.+\..+/.test(valueInput) || "E-mail inválido"
      ];
    },
    setDocument() {
      return this.account
        ? this.service.documentFormat(this.account.document)
        : this.service.documentFormat(this.account.document);
    }
  },
  methods: {
    ...mapActions({
      tokenPix: "Pix/sendTokenPix",
      getAllDataKeys: "Pix/getAllPixKeys",
      createKey: "Pix/createPixKey"
    }),
    ...mapMutations({
      setDataKey: "Pix/SET_DATA_KEY",
      setRequest: "Pix/SET_REQUEST",
      setKeyExists: "Pix/SET_KEY_EXISTS"
    }),
    setActiveMail() {
      this.activeMail = false;
      this.activePhone = true;
      this.activeAleatory = true;
      this.activeDocument = true;
    },
    setActivePhone() {
      this.activePhone = false;
      this.activeMail = true;
      this.activeAleatory = true;
      this.activeDocument = true;
    },
    setActiveAleatory() {
      this.activeAleatory = false;
      this.activeMail = true;
      this.activePhone = true;
      this.activeDocument = true;
    },
    setActiveDocument() {
      this.activeDocument = false;
      this.activeMail = true;
      this.activePhone = true;
      this.activeAleatory = true;
    },
    async cancel() {
      await this.consultKey();
      if (this.getKeyExists) {
        (this.firstKeys = false), (this.myKeys = true), (this.registrationKeys = false);
      } else {
        this.$router.push({ path: `/customer/digitalAccount/pix` });
      }
    },
    async sendToken(value) {
      if (
        (this.radioKey === "mail" && this.$refs.mail.validate()) ||
        (this.mailKey && this.$refs.mail.validate())
      ) {
        this.setRequest(["create"]);
        await this.createKey({
          token: "",
          name: this.mailKey,
          type: "email"
        });
        await this.setDataKey(["email", this.mailKey]);
        if (this.erro) {
          this.setDataKey(null);
          this.mailKey = "";
        } else {
          this.dialog = true;
        }
      }
      if (
        (this.radioKey === "phone" && this.$refs.phone.validate()) ||
        (this.phoneKey && this.$refs.phone.validate())
      ) {
        this.setRequest(["create"]);
        await this.cleanPhone(this.phoneKey);
        await this.createKey({
          token: "",
          name: this.mobilePhone,
          type: "mobile_phone"
        });
        await this.setDataKey(["mobile_phone", this.mobilePhone]);
        if (this.erro) {
          this.setDataKey(null);
          this.phoneKey = "";
        } else {
          this.dialog = true;
        }
      }
      if (this.radioKey === "document" || value === "document") {
        await this.tokenPix();
        if (this.erro) {
          return;
        } else {
          this.setRequest(["create"]);
          this.documentKey = this.account.document;
          this.setDataKey(["cnpj", this.documentKey]);
          this.dialog = true;
        }
      }
      if (this.radioKey === "aleatory" || value === "aleatory") {
        await this.tokenPix();
        if (this.erro) {
          return;
        } else {
          this.setRequest(["create"]);
          this.setDataKey(["random", ""]);
          this.dialog = true;
        }
      }
    },
    async requestDelete(payload) {
      this.tokenPix();
      await this.setRequest(["delete", payload]);
      this.dialog = true;
    },
    async closeModal() {
      this.dialog = false;
      this.enableDocument = true;
      this.setDataKey(null);
      this.reset();
      this.consultKey();
    },
    cancelModal() {
      this.getAllDataKeys({
        page: 1,
        perPage: 20
      });
      this.dialog = false;
      this.setDataKey(null);
      this.reset();
    },
    reset() {
      this.mailKey = "";
      this.phoneKey = "";
      this.radioKey = "";
      this.activeDocument = true;
      this.activeAleatory = true;
      this.activePhone = true;
      this.activeMail = true;
      this.setDataKey(null);
    },
    cleanPhone(payload) {
      this.mobilePhone = this.service.cleanMobilePhone(payload);
    },
    async pushToMyKeys() {
      await this.consultKey();
      await this.verifyStatus();
      if (this.getKeyExists && this.abledKey && this.getDataKeys.items[0].alias.length !== 0) {
        (this.firstKeys = false), (this.myKeys = true), (this.registrationKeys = false);
      } else {
        (this.firstKeys = true),
          (this.myKeys = false),
          (this.registrationKeys = false),
          (this.radioKey = "document");
      }
    },
    async pushToRegistration() {
      if (this.getKeyExists) {
        await this.getAllDataKeys({
          page: 1,
          perPage: 20
        });
        await this.documentExists();
        this.myKeys = false;
        this.firstKeys = false;
        this.registrationKeys = true;
        this.reset();
      } else {
        this.enableDocument = true;
        this.myKeys = false;
        this.firstKeys = true;
        this.registrationKeys = false;
        this.reset();
      }
    },
    backHome() {
      const home = "home";
      return this.$router.push({ path: `/customer/${home}` });
    },
    async consultKey() {
      await this.getAllDataKeys({
        page: 1,
        perPage: 20
      });
      if (this.getDataKeys.items[0].alias !== null && this.getDataKeys.items[0].alias.legth !== 0) {
        this.setKeyExists(true);
      } else {
        this.setKeyExists(false);
      }
    },
    verifyStatus() {
      if (this.getDataKeys.items[0].alias !== null) {
        if (this.getDataKeys.items[0].alias.length !== 0) {
          const sum = this.getDataKeys.items[0].alias.reduce((acc, el) => {
            if (el.status === true) acc++;
            return acc;
          }, 0);
          this.abledKey = sum > 0;
        }
      }
    },
    async documentExists() {
      let index;
      for (index in this.getDataKeys.items[0].alias) {
        if (this.getDataKeys.items[0].alias[index].type === "cnpj") {
          this.enableDocument = false;
        }
      }
    }
  },
  async created() {
    await this.pushToMyKeys();
    this.documentExists();
  }
};
</script>
<style lang="scss" scoped>
@import "~@/assets/styles/digitalAccount/pix.scss";
.row {
  margin: 0 -12px !important;
}
</style>
